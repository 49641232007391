.loginPage {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-flow: column;

    justify-content: center;
    align-items: center;

}

.loginPage > .loginField {
    display: flex;
    flex-flow: column;
}

.loginPage > .loginField * {
    margin: 0.3em
}

#alert {
    position: absolute;
    top: 25%;
    left: 50%;

    opacity: 0;
    transition: opacity 0.3s ease-in;
}

#alert > span {
    position: relative;
    left: -50%;
    padding: 1em;
    border: 1px solid black;
    border-radius: 4px;
    background-color: rgba(191,0,16,0.64);
}